import { Component } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
	selector: 'confirmation-component',
	templateUrl: 'confirmation.component.html',
	styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
	public readonly messageKey: string;

	constructor(
		public readonly navParams: NavParams,
		public readonly popoverController: PopoverController
	) {
		this.messageKey = this.navParams.data.message;
	}

	public answer(answer: boolean): void {
		void this.popoverController.dismiss(answer).catch();
	}
}
