import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import { StorageKey } from '@shared-libs/enums';
import { ValidationService } from './validation.service';

/**
 * The storage service to handle persistent storage of data as key-value pair
 *
 * When the client is a web client, local storage is used. When the client is a native client, native storage is used.
 */
@Injectable({
	providedIn: 'root',
})
export class StorageService {
	constructor(private readonly validationService: ValidationService) {}

	/**
	 * Get an item from persistent storage
	 * @param _key The key linked to the data as {@link StorageKey}
	 * @param opts Options for the observable
	 * @param opts.throwOnFailure If set to false, the observable will not throw an error if the key is not found, but will return null instead
	 * @returns An observable containing the data linked to the _key
	 */
	public getItem<Model>(_key: StorageKey, opts = { throwOnFailure: true }): Observable<Model> {
		return new Observable((subscriber) => {
			if (this.validationService.isOnMobile()) {
				Preferences.get({ key: _key })
					.then((result) => {
						subscriber.next(JSON.parse(result.value));
						subscriber.complete();
					})
					.catch(() => {
						if (!opts || opts?.throwOnFailure) {
							subscriber.error({ code: 404, message: `Failed to find ${_key}` });
						} else {
							subscriber.next(null);
							subscriber.complete();
						}
					});
			} else {
				const item = JSON.parse(localStorage.getItem(_key)) as Model;
				if (item === null && (!opts || opts?.throwOnFailure)) {
					subscriber.error({ code: 404, message: `Failed to find ${_key}` });
				} else {
					subscriber.next(item);
					subscriber.complete();
				}
			}
		});
	}

	/**
	 * Set an item in persistent storage as key-value pair
	 * @param _key The key that has to be linked to the data as {@link StorageKey}
	 * @param _data The data that needs to be stored
	 * @returns An empty observable confirming the storage is successful
	 */
	public setItem(_key: StorageKey, _data: any): Observable<void> {
		return new Observable((subscriber) => {
			if (this.validationService.isOnMobile()) {
				Preferences.set({ key: _key, value: JSON.stringify(_data) })
					.then(() => {
						subscriber.next();
						subscriber.complete();
					})
					.catch((err) => subscriber.error(err));
			} else {
				localStorage.setItem(_key, JSON.stringify(_data));
				subscriber.next();
				subscriber.complete();
			}
		});
	}

	/**
	 * Remove an item from persistent storage
	 * @param _key The key linked to the data as {@link StorageKey}
	 * @returns An empty observable confirming the storage is successful
	 */
	public removeItem(_key: StorageKey): Observable<void> {
		return new Observable((subscriber) => {
			if (this.validationService.isOnMobile()) {
				void Preferences.remove({ key: _key }).catch();
			} else {
				localStorage.removeItem(_key);
				subscriber.next();
				subscriber.complete();
			}
		});
	}

	/**
	 * Remove all data from persistent storage
	 */
	public clearAll(): void {
		if (this.validationService.isOnMobile()) {
			void Preferences.clear().catch();
		}
		localStorage.clear();
	}
}
