import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationPermissionsComponent } from './location-permissions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RegionProvider } from '@internal-providers/beecloud/region.provider';
import { PopupHeaderComponentModule } from '@structural-components/header-components/popup-header-component/popup-header.component.module';
import { DirectivesModule } from '@shared-directives/directives.module';
import { TranslationModule } from '@app/app-translation.module';
import { PipesModule } from '@shared-pipes/pipes.module';

@NgModule({
	declarations: [LocationPermissionsComponent],
	exports: [LocationPermissionsComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		IonicModule,
		TranslationModule,
		PopupHeaderComponentModule,
		DirectivesModule,
		PipesModule,
	],
	providers: [RegionProvider],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LocationPermissionsComponentModule {}
