import { TranslationService } from './../services/translation.service';

/**
 * a Method used by APP_INITIALIZER to initialize a language file before the initialization of the app is complete.
 * This way you don't have issues with translation files not loaded on time, and seeing the translation keys
 * @param translationService The translation service
 * @returns A promise that loads the initial language file
 */
export const translationInitializerFactory =
	(translationService: TranslationService): (() => Promise<void>) =>
	async () =>
		await translationService.initialize();
