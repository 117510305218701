import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageKey } from '@shared-libs/enums';
import { StorageService } from './storage.service';
import { Subject, firstValueFrom } from 'rxjs';
export type SupportedLanguages = 'nl-BE' | 'en-GB' | 'fr-FR';

/**
 * The translation service to manage translations
 */
@Injectable({
	providedIn: 'root',
})
export class TranslationService {
	private currentLanguage: SupportedLanguages;
	private readonly languageChangedSubject: Subject<SupportedLanguages>;
	private readonly supportedLanguages: Array<{ code: SupportedLanguages; name: string }> = [
		{ code: 'nl-BE', name: 'Nederlands' },
		{ code: 'en-GB', name: 'English' },
		{ code: 'fr-FR', name: 'Français' },
	];

	constructor(
		private readonly translateService: TranslateService,
		private readonly storageService: StorageService
	) {
		this.languageChangedSubject = new Subject();
	}

	public async initialize(): Promise<void> {
		let loaded = await this.loadLanguageFromStorage();
		if (!loaded) {
			loaded = await this.loadLanguageFromBrowser();
		}
		if (!loaded) {
			await this.changeLanguage(this.translateService.getDefaultLang() as SupportedLanguages);
		}
	}

	public getCurrentLanguage(): SupportedLanguages {
		return this.currentLanguage ?? 'en-GB';
	}

	public onLanguageChanged(): Subject<SupportedLanguages> {
		return this.languageChangedSubject;
	}

	public getSupportedLanguages(): Array<{ code: SupportedLanguages; name: string }> {
		return this.supportedLanguages;
	}

	public async changeLanguage(language: SupportedLanguages): Promise<void> {
		this.currentLanguage = language;
		await firstValueFrom(this.translateService.use(language));
		this.languageChangedSubject.next(language);
		this.storageService.setItem(StorageKey.language, language).subscribe();
	}

	public translate(translationKey: string): string {
		return this.translateService.instant(translationKey);
	}

	private async loadLanguageFromStorage(): Promise<boolean> {
		try {
			const storedLanguage = await firstValueFrom(
				this.storageService.getItem<SupportedLanguages>(StorageKey.language)
			);
			if (storedLanguage) {
				await this.changeLanguage(storedLanguage);
				return true;
			}
		} catch (err) {}
		return false;
	}

	private async loadLanguageFromBrowser(): Promise<boolean> {
		let cultureLang = this.translateService.getBrowserCultureLang();
		if (cultureLang.includes('nl')) {
			cultureLang = 'nl-BE';
		}
		if (cultureLang.includes('en')) {
			cultureLang = 'en-GB';
		}
		if (cultureLang.includes('fr')) {
			cultureLang = 'fr-FR';
		}
		if (cultureLang) {
			await this.changeLanguage(this.translateService.getDefaultLang() as SupportedLanguages);
			return true;
		}
		return false;
	}
}
