import { Api } from '@shared-providers/api.provider';
import { Observable } from 'rxjs';
import { IApiObject, IChangePasswordCredentials, ILoginCredentials } from '@shared-libs/interfaces';
import { Injectable } from '@angular/core';
import { Provider } from '@shared-providers/Provider';
import { LoadingService } from '@shared-services/loading.service';
import { IUser } from '@shared/models/user.model';

export enum LoginEndpoints {
	login = '/login',
	isActive = '/login/active',
}

@Injectable({
	providedIn: 'root',
})
export class LoginProvider extends Provider<IUser> {
	protected readonly url: string = '/login';

	constructor(
		private readonly api: Api,
		protected readonly loadingService: LoadingService
	) {
		super(loadingService);
	}

	public login(_credentials: ILoginCredentials): Observable<IApiObject<IUser>> {
		return this.process(this.api.post<any>(LoginEndpoints.login, _credentials)) as Observable<IApiObject<IUser>>;
	}

	public requestReset(_credentials: { username: string }): Observable<IApiObject<any>> {
		return this.process(this.api.post<any>(`${this.url}/reset`, _credentials)) as Observable<IApiObject<IUser>>;
	}

	public reset(_credentials: { token: string; password: string }): Observable<IApiObject<any>> {
		return this.process(this.api.post<any>(`${this.url}/reset/confirm`, _credentials)) as Observable<
			IApiObject<IUser>
		>;
	}

	public patchPassword(
		employeeId: string,
		_credentials: Pick<IChangePasswordCredentials, 'new'>
	): Observable<IApiObject> {
		return this.process(
			this.api.put(`/employees/${employeeId}/initialize/password`, { password: _credentials.new })
		);
	}

	public updatePassword(_credentials: IChangePasswordCredentials): Observable<IApiObject> {
		return this.process(this.api.post(`${this.url}/change`, _credentials));
	}

	public hasChangedPassword(): Observable<IApiObject<boolean>> {
		return this.process(this.api.get(`${this.url}/change`)) as Observable<IApiObject<boolean>>;
	}

	public isActive(): Observable<IApiObject<boolean>> {
		return this.process(this.api.get(LoginEndpoints.isActive)) as Observable<IApiObject<boolean>>;
	}
}
