import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReleaseNotesComponent } from './release-notes.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PopupHeaderComponentModule } from '@structural-components/header-components/popup-header-component/popup-header.component.module';
import { DirectivesModule } from '@shared-directives/directives.module';
import { TranslationModule } from '@app/app-translation.module';

@NgModule({
	declarations: [ReleaseNotesComponent],
	exports: [ReleaseNotesComponent],
	imports: [CommonModule, FormsModule, IonicModule, TranslationModule, PopupHeaderComponentModule, DirectivesModule],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReleaseNotesComponentModule {}
