/* eslint-disable no-console */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { initializeSentry } from '@app/modules/shared/providers/error.handler';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

(window as any).__rootdir__ = window.origin;

initializeSentry();

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.log(err));
