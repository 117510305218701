import { Api } from '@shared-providers/api.provider';
import { Observable } from 'rxjs';
import { IApiObject } from '@shared-libs/interfaces';
import { Injectable } from '@angular/core';
import { Provider } from '@shared-providers/Provider';
import { LoadingService } from '@shared-services/loading.service';
import { IReleaseNote } from '@shared/models/release-note.model';

@Injectable({
	providedIn: 'root',
})
export class ReleaseNoteProvider extends Provider<IReleaseNote> {
	protected readonly url: string = '/release-notes';

	constructor(
		private readonly api: Api,
		protected readonly loadingService: LoadingService
	) {
		super(loadingService);
	}

	public getLatest(): Observable<IApiObject<IReleaseNote>> {
		return this.process(this.api.get(`${this.url}/latest`), false) as Observable<IApiObject<IReleaseNote>>;
	}

	public hasSeenLatest(): Observable<IApiObject<{ seen: boolean; latest: IReleaseNote }>> {
		return this.process(this.api.get(`${this.url}/seen`), false) as Observable<IApiObject>;
	}

	public seenReleaseNote(id: string): Observable<IApiObject> {
		return this.process(this.api.post(`${this.url}/${id}`, {}), false) as Observable<IApiObject>;
	}
}
