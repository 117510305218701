import { HttpClient } from '@angular/common/http';
import { TranslateFakeLoader, TranslateLoader, TranslateModule, TranslateModuleConfig } from '@ngx-translate/core';
import { HttpLoaderFactory } from '@shared-factories/http-loader.factory';

export const translateConfig: TranslateModuleConfig = {
	loader: {
		provide: TranslateLoader,
		useFactory: HttpLoaderFactory,
		deps: [HttpClient],
	},
};

export const TranslationModule = TranslateModule.forChild(translateConfig);

export const TranslationMockModule = TranslateModule.forRoot({
	loader: {
		provide: TranslateLoader,
		useClass: TranslateFakeLoader,
	},
});
