import { environment as ENV, environment } from '@environments/environment';
/* eslint-disable no-console */
import { ErrorHandler, Injectable } from '@angular/core';
import {
	Event,
	captureException,
	configureScope,
	init as initSentry,
	routingInstrumentation,
} from '@sentry/angular-ivy';

import { AppManager } from '@app/modules/shared/managers/app.manager';
import { BrowserTracing } from '@sentry/browser';
import { Environment } from '@shared-libs/enums';
import { HttpErrorResponse } from '@angular/common/http';
import { UserDetailsManager } from '@shared-managers/user-details.manager';
import { UserManager } from '@shared-managers/user.manager';
import version from '@root/src/version.json';

export const initializeSentry = (): void => {
	if (!environment.sentryDsn) return;
	initSentry({
		dsn: environment.sentryDsn,
		environment: environment.environment,
		release: `beecloud-frontend@${version.version}`,
		integrations: [
			new BrowserTracing({
				tracingOrigins: [ENV.BASE_URL],
				routingInstrumentation,
			}),
		],
		tracesSampleRate: 0.1,
		beforeSend: (event: Event): Event => {
			// Filter out BEECLOUD errors, because they are logged in the back-end sentry
			if (
				(event?.extra?.__serialized__ as any)?.name === 'HttpErrorResponse' &&
				((event?.extra?.__serialized__ as any)?.url as string).includes(ENV.BASE_URL)
			) {
				return null;
			}
			return event;
		},
	});
};

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
	constructor(
		private readonly userDetailsManager: UserDetailsManager,
		private readonly userManager: UserManager,
		private readonly appManager: AppManager
	) {}

	public handleError(error: any): void {
		if (error && error.url && (error.url as string).includes(ENV.BASE_URL)) {
			return;
		}
		this.configureSentry(error);
		const extractedError = this.extractError(error);
		captureException(extractedError || 'Handled unknown error');
		if (this.appManager.getEnvironment() !== Environment.Production) {
			console.error(error);
		}
	}

	private configureSentry(_originalError?: any): void {
		configureScope((scope) => {
			scope.setUser({
				role: this.userManager.getRole(),
				id: this.userManager.getUserId(),
				userDetails: this.userDetailsManager.getUserDetails(),
			});
			scope.setContext('BEECLOUD', { version: this.appManager.getVersion() });
			scope.setTag('Version', this.appManager.getVersion());
			if (_originalError && typeof _originalError !== 'string') {
				try {
					scope.setContext('Original Error', JSON.parse(JSON.stringify(_originalError)));
				} catch (err) {
					scope.setContext('Original Error', _originalError);
				}
			}
		});
	}

	private extractError(_error: any): any {
		let error = _error;

		if (error?.ngOriginalError) {
			error = error.ngOriginalError;
		}
		// We can handle messages and Error objects directly.
		if (typeof error === 'string' || error instanceof Error) {
			return error;
		}

		// If it's http module error, extract as much information from it as we can.
		if (error instanceof HttpErrorResponse) {
			// The `error` property of http exception can be either an `Error` object, which we can use directly...
			if (error.error instanceof Error || error?.error.message) {
				return error.error as string | Error;
			}

			// ... or an`ErrorEvent`, which can provide us with the message but no stack...
			if (error.error instanceof ErrorEvent) {
				return error.error.message;
			}

			// ...or the request body itself, which we can use as a message instead.
			if (typeof error.error === 'string') {
				return `Server returned code ${error.status} with body "${error.error}"`;
			}

			// If we don't have any detailed information, fallback to the request message itself.
			return error.message;
		}

		// Skip if there's no error, and let user decide what to do with it.
		return null;
	}
}
