import { Injectable } from '@angular/core';
import { ModalController, PopoverController, ToastController } from '@ionic/angular';
import { ErrorComponent } from '@structural-components/error-component/error.component';
import { IApiError } from '@shared-libs/interfaces';
import { ValidationService } from './validation.service';
import { ErrorType } from '@shared-libs/enums';
import { HttpErrorResponse } from '@angular/common/http';
import { WrongVersionErrorComponent } from '@shared-components/wrong-version-error-component/wrong-version-error.component';

/**
 * The error service to handle errors and error messages accross the application
 */
@Injectable({
	providedIn: 'root',
})
export class ErrorService {
	private apiErrorIsShowing: boolean = false;
	private wrongVersionErrorIsShowing: boolean = false;

	constructor(
		private readonly popoverController: PopoverController,
		private readonly modalController: ModalController,
		private readonly toastController: ToastController,
		private readonly validationService: ValidationService
	) {}

	/**
	 * A method that handles all api errors. Some error we need to handle different (ex. show a unique modal)
	 * than the default api error popover we show.
	 * @param error The HttpErrorResponse
	 * @returns A promise
	 */
	public async handleApiError(error: HttpErrorResponse): Promise<void> {
		switch (error.error.message) {
			case 'ERRORS.AUTH.WRONG_VERSION':
				return this.showWrongVersionError();
			default:
				return this.showApiError(error);
		}
	}

	/**
	 * Shows the ErrorComponent in a popover containing the error message returned from the API
	 * @param _error The returned error
	 * @returns An empty promise to confirm the actual presentation of the popover
	 */
	public async showApiError(_error: HttpErrorResponse | any): Promise<void> {
		if (!this.apiErrorIsShowing) {
			this.apiErrorIsShowing = true;
			const error: IApiError = {
				status: _error.status,
				message: _error.error.i18n || _error.error.message || _error.message,
			};
			const popover = await this.popoverController.create({
				component: ErrorComponent,
				componentProps: { error },
			});
			void popover
				.onDidDismiss()
				.then(() => {
					this.apiErrorIsShowing = false;
				})
				.catch();
			await popover.present();
		}
	}

	/**
	 * Show a user error, can be shown as toast (default) or in a component
	 * @param _message The error to show to the user
	 * @param type The error type
	 */
	public showApplicationError(_message: string, type: ErrorType = ErrorType.Toast): void {
		switch (type) {
			case ErrorType.Component:
				void this.showComponentError(_message);
				break;
			case ErrorType.PermanentToast:
				this.showPermanentToastError(_message);
				break;
			case ErrorType.Toast:
			default:
				this.showToastError(_message);
				break;
		}
	}

	private showToastError(_message: string): void {
		void this.toastController
			.create({
				message: `${_message}`,
				duration: 4000,
				cssClass: '_error-toast',
				position: this.validationService.isOnMobileTemplate() ? 'middle' : 'bottom',
			})
			.then((toast) => {
				void toast.present().catch();
			})
			.catch();
	}

	private showPermanentToastError(_message: string): void {
		void this.toastController
			.create({
				message: _message,
				buttons: [
					{
						text: 'Ok',
						handler: () => {},
					},
				],
				cssClass: '_error-toast',
				position: this.validationService.isOnMobileTemplate() ? 'middle' : 'bottom',
			})
			.then((toast) => {
				void toast.present().catch();
			})
			.catch();
	}

	private async showComponentError(_message: string): Promise<void> {
		const popover = await this.popoverController.create({
			component: ErrorComponent,
			componentProps: { error: { message: _message } },
		});
		await popover.present();
	}

	private async showWrongVersionError(): Promise<void> {
		if (!this.wrongVersionErrorIsShowing) {
			this.wrongVersionErrorIsShowing = true;
			const modal = await this.modalController.create({
				component: WrongVersionErrorComponent,
			});
			void modal
				.onDidDismiss()
				.then(() => {
					this.wrongVersionErrorIsShowing = false;
				})
				.catch();
			await modal.present();
		}
	}
}
