import { IApiObject, IListData } from '@shared-libs/interfaces';
import { Injectable, Injector } from '@angular/core';

import { AppManager } from '@app/modules/shared/managers/app.manager';
import { environment as ENV } from '@environments/environment';
import { ErrorService } from '@shared-services/error.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslationService } from '@shared-services/translation.service';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';

enum HTTP_METHOD {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	DELETE = 'DELETE',
	SEARCH = 'SEARCH',
	DOWNLOAD = 'DOWNLOAD',
}

interface IRequestOptions {
	hideError?: boolean;
	baseUrl?: string;
}

/**
 * A general API class that handles requests to the BEECLOUD api
 */
@Injectable({
	providedIn: 'root',
})
export class Api {
	private readonly baseurl: string = ENV.BASE_URL;
	private readonly headers: { [header: string]: string | string[] };

	constructor(
		private readonly http: HttpClient,
		private readonly errorService: ErrorService,
		private readonly appManager: AppManager,
		private readonly translationService: TranslationService
	) {
		this.headers = {
			IfMatch: this.appManager.getVersion(),
			'Accept-Language': this.translationService.getCurrentLanguage(),
		};

		this.translationService.onLanguageChanged().subscribe((language) => {
			this.headers['Accept-Language'] = language;
		});
	}

	/**
	 * A GET request to the BEECLOUD url
	 * @param _url The specific url for the request
	 * @param opts IRequestOptions
	 * @returns An observable which contains the result of the request in an {@link IApiObject}
	 */
	public get<M>(_url: string, opts?: IRequestOptions): Observable<IApiObject<M>> {
		return this.request<M>(HTTP_METHOD.GET, _url, null, opts);
	}

	/**
	 * A SEARCH request the BEECLOUD url
	 * @param _url The specific url for the request
	 * @param _body The body for the SEARCH request (should be the same as the resulting object)
	 * The search HTTP method is not supported by nest, so is adjusted to POST
	 * @returns An observable which contains the result of the request in an {@link IApiObject}
	 */
	public search<Model>(
		_url: string,
		_body: Partial<Model>
	): Observable<IApiObject<Model | Array<Model> | IListData<Model>>> {
		return this.request(HTTP_METHOD.POST, _url, _body);
	}

	/**
	 * A POST request the BEECLOUD url
	 * @param _url The specific url for the request
	 * @param _body The body for the POST request
	 * @param opts IRequestOptions
	 * @returns An observable which contains the result of the request in an {@link IApiObject}
	 */
	public post<Model>(_url: string, _body: Model, opts?: IRequestOptions): Observable<IApiObject<Model>> {
		return this.request(HTTP_METHOD.POST, _url, _body, opts);
	}

	/**
	 * A PUT request the BEECLOUD url
	 * @param _url The specific url for the request
	 * @param _body The body for the PUT request
	 * @param opts IRequestOptions
	 * @returns An observable which contains the result of the request in an {@link IApiObject}
	 */
	public put<Model>(_url: string, _body: Partial<Model>, opts?: IRequestOptions): Observable<IApiObject<Model>> {
		return this.request(HTTP_METHOD.PUT, _url, _body, opts);
	}

	/**
	 * A DELETE request the BEECLOUD url
	 * @param _url The specific url for the request
	 * @param _body The body for the DELETE request
	 * @returns An observable which contains the result of the request in an {@link IApiObject}
	 */
	public delete<Model>(_url: string, _body?: Partial<Model>): Observable<IApiObject> {
		return this.request(HTTP_METHOD.DELETE, _url, _body);
	}

	/**
	 * A specialist download method that receives a file or blob from the beecloud api
	 * @param _url The api url
	 * @param _filename The filename to give to the file when it is returned
	 * @param _data The data for the download request when needed
	 * @returns An observable which contains the result of the request in an {@link IApiObject}
	 */
	public download(_url: string, _filename: string, _data: any = {}): Observable<any> {
		return new Observable((subscriber) => {
			this.http
				.post(this.baseurl + _url, _data, { headers: this.headers, responseType: 'blob', observe: 'response' })
				.pipe(
					map((res) => ({
						headers: res.headers,
						content: res.body,
					}))
				)
				.subscribe(
					(_response) => {
						if (!/application\/json/.test(_response.headers.get('Content-Type'))) {
							const fileBlob = new Blob([_response.content]);
							saveAs(fileBlob, _filename);
							subscriber.next(_response.content);
						} else {
							void this.errorService.showApiError(new Error('Invalid file format')).catch();
							subscriber.error();
						}
					},
					(error) => {
						const fileReader = new FileReader();
						fileReader.onload = () => {
							error.message = JSON.parse(fileReader.result as string).message;
							void this.errorService.showApiError(error).catch();
							subscriber.error();
						};
						fileReader.readAsText(error.error);
					},
					() => subscriber.complete()
				);
		});
	}

	/**
	 * A general request handler
	 * @param method The HTTP method
	 * @param url The api url
	 * @param body The request body when needed (for POST, PUT and SEARCH requests)
	 * @param opts Additional options
	 * @param opts.hideError whether or not to show an error to the user
	 * @param opts.baseUrl Override the base url
	 * @returns An observable which contains the result of the request in an {@link IApiObject}
	 */
	private request<Model>(
		method: HTTP_METHOD,
		url: string,
		body?: any,
		opts?: IRequestOptions
	): Observable<IApiObject<any>> {
		return new Observable((subscriber) => {
			this.http
				.request(method, (opts?.baseUrl || this.baseurl) + url, { body: body, headers: this.headers })
				.subscribe({
					next: (_result) => {
						subscriber.next({
							source: 'API',
							data: (_result as IApiObject<Model>).data,
							exceptions: (_result as IApiObject<Model>).exceptions,
						});
					},
					error: (error) => {
						if (!opts?.hideError === true) {
							void this.errorService.handleApiError(error).catch();
						}
						subscriber.error(error);
					},
					complete: () => subscriber.complete(),
				});
		});
	}
}
