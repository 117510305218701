import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QrCodeComponent } from './qr-code.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PopupHeaderComponentModule } from '@structural-components/header-components/popup-header-component/popup-header.component.module';
import { PipesModule } from '@shared-pipes/pipes.module';
import { TranslationModule } from '@app/app-translation.module';

@NgModule({
	declarations: [QrCodeComponent],
	exports: [QrCodeComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		IonicModule,
		TranslationModule,
		PopupHeaderComponentModule,
		PipesModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class QrCodeComponentModule {}
