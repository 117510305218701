<div class="_release-notes-component inner-content">

	<popup-header-component [showCloseButton]="false">
		<p class="_title" [translate]="'RELEASE_NOTE.TITLE'"></p>
		<p class="_subtitle" [translate]="'RELEASE_NOTE.VERSION'" [translateParams]="{version: notes.version}"></p>
	</popup-header-component>

	<div class="_text-container">
		<p [innerHTML]="notes.text"></p>
	</div>
	
    <div class="_buttons">
        <button class="_button _save-button" (click)="seen()" [translate]="'BUTTONS.OK'" ></button>
    </div>
        
</div>



