import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrongVersionErrorComponent } from './wrong-version-error.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { TranslationModule } from '@app/app-translation.module';

@NgModule({
	declarations: [WrongVersionErrorComponent],
	exports: [WrongVersionErrorComponent],
	imports: [CommonModule, FormsModule, IonicModule, TranslationModule],
	providers: [],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WrongVersionErrorComponentModule {}
