import { ICommunicationOptions } from './interfaces';

export const VALIDATION = {
	EMAIL_REGEX: new RegExp("^[a-zA-Z0-9._'%+-]+@[a-zA-Z0-9-]+.([a-zA-Z0-9-]+.)*[a-zA-Z0-9-]{2,}$"),
	STRING_REGEX: new RegExp('[^]+'),
	NAME_REGEX: new RegExp('^[A-zÀ-úäöüÄÖÜâêûîôÂÊÛÎÔ .-]+$'),
	DISCOUNT_CODE_REGEX: new RegExp('^[A-z0-9]+$'),
	PHONE_REGEX: new RegExp('^([+0-9]{9,13})$'),
	ZIPCODE_REGEX: new RegExp('^[1-9]{1}[0-9]{3}$'),
	ZIPCODE_LIST_REGEX: /^([1-9]{1}[0-9]{3})(,\s?[1-9]{1}[0-9]{3})*$/,
	BIRTHYEAR_REGEX: new RegExp('^(19|20)[0-9]{2}$'),
	TIME_REGEX: new RegExp('^([0-9:]{5,8})$'),
	SOCIAL_REGEX: new RegExp('^[0-9]{2}[.]{0,1}[0-9]{2}[.]{0,1}[0-9]{2}[-]{0,1}[0-9]{3}[.]{0,1}[0-9]{2}$'),
	// eslint-disable-next-line max-len
	VAT_REGEX: new RegExp(
		'^((AT)?U[0-9]{8}|(BE)?[0-9]{10}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$'
	),
	URL_REGEX: new RegExp('^[https:|http:|www.]S*'),
	DATE_STRING: new RegExp('^([12]d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]d|3[01]))$'),
	COUNTRY_CODE: new RegExp('^[A-Z]{2}$'),
};

export const DEFAULT_ZIPCODE_RADIUS = 5;

export const BASE_COMMUNICATION_OPTIONS: Array<ICommunicationOptions> = [
	{ key: 'client', value: true, translatedKey: 'Klant' },
	{ key: 'employee', value: true, translatedKey: 'Consultant' },
	{ key: 'payroller', value: false, translatedKey: 'Payroller' },
];

export const BANK_ACCOUNT_NUMBER = 'BE90 7310 4697 3132';

export const PRICING = {
	MEMBERSHIP_COST: 3.95,
	HALFHOUR_PRICE: 19,
	KMS_BUITEN_RING_PRICE: 5,
	WEEKEND_COST: 19,
	TAX: 21,
};

const EVENT_COLORS = {
	GREY: '#a8a8a8',
	LIGHT_GREY: 'lightgrey',
	GREEN: '#7fbf7f',
	LIGHT_GREEN: '#c7e1c7',
	ORANGE: '#edb142',
	BLUE: '#89B8F8',
};

export const CONSULTANT_CALENDAR_EVENT_COLORS = {
	WORKBLOCK_PAST: EVENT_COLORS.LIGHT_GREY,
	WORKBLOCK_AVAILABLE: EVENT_COLORS.GREEN,
	WORKBLOCK_UNAVAILABLE: EVENT_COLORS.LIGHT_GREY,
	ASSIGNEMENT_PAST: EVENT_COLORS.GREY,
	ASSIGNEMENT: EVENT_COLORS.ORANGE,
};

export const CALENDAR_EVENT_COLORS = {
	APPOINTMENT: EVENT_COLORS.ORANGE,
	APPOINTMENT_PAST: EVENT_COLORS.GREY,
	DIGIPOINT: EVENT_COLORS.ORANGE,
	DIGIPOINT_PAST: EVENT_COLORS.GREY,
	WORKBLOCK: EVENT_COLORS.GREEN,
	WORKBLOCK_INVALID: EVENT_COLORS.LIGHT_GREEN,
	WORKBLOCK_PAST: EVENT_COLORS.LIGHT_GREY,
	WORKBLOCK_RESERVED: EVENT_COLORS.BLUE,
	WORKBLOCK_UNAVAILABLE: EVENT_COLORS.LIGHT_GREY,
	SELECTED: EVENT_COLORS.ORANGE,
};

export const TEMPLATE_WIDTH_CHANGE = 1025;
