import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injector, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpLoaderFactory } from '@app/modules/shared/factories/http-loader.factory';
import { translationInitializerFactory } from '@app/modules/shared/factories/translation-loader.factory';
import localeBE from '@angular/common/locales/nl-BE';
import localeEN from '@angular/common/locales/en-GB';
import localeFR from '@angular/common/locales/fr';

import { registerLocaleData, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgSelectModule } from '@ng-select/ng-select';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@busacca/ng-pick-datetime';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { Interceptor } from './modules/shared/providers/interceptor';
import { SentryErrorHandler } from './modules/shared/providers/error.handler';
import { SharedModule } from '@shared/shared.module';
import { TranslationService } from '@shared-services/translation.service';
import { FormDateModule, FormTextareaModule } from '@beego/ngx-form-components';
// import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';

registerLocaleData(localeBE);
registerLocaleData(localeEN);
registerLocaleData(localeFR);
@NgModule({
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		IonicModule.forRoot({ animated: false }),
		TranslateModule.forRoot({
			defaultLanguage: 'en-GB',
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		AppRoutingModule,
		HttpClientModule,
		LeafletModule,
		NgSelectModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		SharedModule,
		FormTextareaModule,
		FormDateModule,
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: translationInitializerFactory,
			deps: [TranslationService],
			multi: true,
		},
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		{
			provide: LOCALE_ID,
			deps: [TranslationService],
			useFactory: (translationService: TranslationService) => translationService.getCurrentLanguage(),
		},
		{ provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
		{ provide: ErrorHandler, useClass: SentryErrorHandler },
		{ provide: Sentry.TraceService, deps: [Router], useValue: undefined },
		HttpClient,
		InAppBrowser,
		// AndroidPermissions
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	/**
	 * Allows for retrieving singletons using `AppModule.injector.get(MyService)`
	 */
	static injector: Injector;
	constructor(
		private readonly trace: Sentry.TraceService,
		private readonly injector: Injector
	) {
		AppModule.injector = this.injector;
	}
}
