import { IApiObject } from '@shared-libs/interfaces';
import { LoadingService } from '@shared-services/loading.service';
import { Observable } from 'rxjs';

export abstract class Provider<Model> {
	protected readonly url: string = '/';

	constructor(protected readonly loadingService: LoadingService) {}

	/**
	 * Process the request
	 *
	 * A framework function to add a loading service and internet listener
	 * @param request The Api request
	 * @param showLoading A parameter to show a loading indicator or not
	 * @returns An observable containing the result of the request as {@link IApiObject}
	 */
	protected process<M = Model>(
		request: Observable<IApiObject<M>>,
		showLoading: boolean = true
	): Observable<IApiObject<M>> {
		if (true /* check for internet */) {
			return new Observable((subscriber) => {
				void this.loadingService
					.startLoading(showLoading)
					.then(() => {
						request.subscribe({
							next: (result) => {
								this.loadingService.stopLoading(showLoading);
								subscriber.next(result);
							},
							error: (error) => {
								this.loadingService.stopLoading(showLoading);
								subscriber.error(error);
							},
							complete: () => subscriber.complete(),
						});
					})
					.catch();
			});
		} else return new Observable((sub) => sub.complete());
	}
}
