<div class="_wrong-version-error-component">
    
    <div class="_intro">
        <!--  <ion-icon src="./assets/icons/download.svg"></ion-icon> -->
        <img src="./assets/icon/icon-only.png" />
        <p class="_title" [translate]="'WRONG_VERSION_COMPONENT.TITLE'"></p>
    </div>

    <div class="_update-container">
        <ng-container *ngIf="os === 'android'">
            <p class="_title" [translate]="'WRONG_VERSION_COMPONENT.ANDROID_SUBTITLE'"></p>
            <button type="button" class="_button _save-button" [translate]="'WRONG_VERSION_COMPONENT.GO_TO_PLAY_STORE'" (click)="goToPlayStore()"></button>
        </ng-container>
    
        <ng-container *ngIf="os === 'ios'">
            <p class="_title" [translate]="'WRONG_VERSION_COMPONENT.IOS_SUBTITLE'"></p>
            <button type="button" class="_button _save-button" [translate]="'WRONG_VERSION_COMPONENT.GO_TO_APP_STORE'" (click)="goToAppStore()"></button>
        </ng-container>
    
        <ng-container *ngIf="os === 'web'">
            <p class="_title" [translate]="'WRONG_VERSION_COMPONENT.WEB_SUBTITLE'"></p>
            <button type="button" class="_button _save-button" [translate]="'WRONG_VERSION_COMPONENT.RELOAD_PAGE'" (click)="reloadPage()"></button>
        </ng-container>
    </div>

</div>