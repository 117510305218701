import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './modules/shared/guards/auth.guard';
import { NgModule } from '@angular/core';

const routes: Routes = [
	{
		path: 'internal',
		loadChildren: () => import('./modules/internal/internal.module').then((m) => m.InternalModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'partner',
		loadChildren: () => import('./modules/partner/partner.module').then((m) => m.PartnerModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'application',
		loadChildren: () =>
			import('./modules/public/solicitation/gui/solicitation-page/solicitation.module').then(
				(m) => m.SolicitationPageModule
			),
	},
	{
		path: 'login',
		loadChildren: () => import('./modules/shared/gui/pages/login-page/login.module').then((m) => m.LoginPageModule),
	},
	{
		path: 'registreren',
		loadChildren: () =>
			import('./modules/shared/gui/pages/register-page/register.module').then((m) => m.RegisterPageModule),
	},
	{
		path: 'reset',
		loadChildren: () => import('./modules/shared/gui/pages/reset-page/reset.module').then((m) => m.ResetPageModule),
	},
	{
		path: 'ratings/digipoint',
		loadChildren: () =>
			import('./modules/public/ratings/gui/pages/rating-digipoint-page/rating-digipoint.module').then(
				(m) => m.RatingDigipointPageModule
			),
	},
	{
		path: 'ratings/appointment',
		loadChildren: () =>
			import('./modules/public/ratings/gui/pages/rating-appointment-page/rating-appointment.module').then(
				(m) => m.RatingAppointmentPageModule
			),
	},
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: '**', redirectTo: '' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: false })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
