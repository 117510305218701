import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Optional, SkipSelf } from '@angular/core';
import { ErrorComponentModule } from '@structural-components/error-component/error.component.module';
import { DirectivesModule } from '@shared-directives/directives.module';
import { ReleaseNotesComponentModule } from '@shared-components/release-notes-component/release-notes.component.module';
import { ConfirmationComponentModule } from '@shared-components/confirmation-component/confirmation.component.module';
import { LocationPermissionsComponentModule } from '@internal-components/location-permissions-component/location-permissions.component.module';
import { WrongVersionErrorComponentModule } from '@shared-components/wrong-version-error-component/wrong-version-error.component.module';
import { QrCodeComponentModule } from '@shared-components/qr-code-component/qr-code.component.module';
import { TransferPaymentComponentModule } from '@shared-components/transfer-payment-component/transfer-payment.component.module';

@NgModule({
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [
		ErrorComponentModule,
		WrongVersionErrorComponentModule,
		QrCodeComponentModule,
		ReleaseNotesComponentModule,
		DirectivesModule,
		ConfirmationComponentModule,
		LocationPermissionsComponentModule,
		TransferPaymentComponentModule,
	],
})
export class SharedModule {
	constructor(@Optional() @SkipSelf() parentModule?: SharedModule) {
		if (parentModule) {
			throw new Error('SharedModule is already loaded. Import it in the AppModule only');
		}
	}
}
