import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationComponent } from './confirmation.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { TranslationModule } from '@app/app-translation.module';

@NgModule({
	declarations: [ConfirmationComponent],
	exports: [ConfirmationComponent],
	imports: [CommonModule, FormsModule, IonicModule, TranslationModule],
	providers: [],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ConfirmationComponentModule {}
