import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { IReleaseNote } from '@shared/models/release-note.model';

@Component({
	selector: 'release-notes-component',
	templateUrl: 'release-notes.component.html',
	styleUrls: ['./release-notes.component.scss'],
})
export class ReleaseNotesComponent {
	public readonly notes: IReleaseNote;

	constructor(
		private readonly navParams: NavParams,
		private readonly modalController: ModalController
	) {
		this.notes = this.navParams.data.notes;
	}

	public async seen(): Promise<void> {
		const modal = await this.modalController.getTop();
		modal.canDismiss = true;
		void this.modalController.dismiss().catch();
	}
}
