import { Api } from '@shared-providers/api.provider';
import { Observable } from 'rxjs';
import { IApiObject } from '@shared-libs/interfaces';
import { Injectable } from '@angular/core';
import { Provider } from '@shared-providers/Provider';
import { LoadingService } from '@shared-services/loading.service';
import { IRegion, ICreateRegion, IUpdateRegion } from '@internal-models/region.model';
import { EmployeeManager } from '@internal-managers/employee.manager';

@Injectable({
	providedIn: 'root',
})
export class RegionProvider extends Provider<IRegion> {
	protected readonly url: string = '/regions';

	constructor(
		private readonly api: Api,
		protected readonly loadingService: LoadingService,
		private readonly employeeManager: EmployeeManager
	) {
		super(loadingService);
	}

	public getRegionsOfEmployee(_employeeId: string): Observable<IApiObject<Array<IRegion>>> {
		return this.process(this.api.get(`${this.url}/consultant/${_employeeId}`)) as Observable<
			IApiObject<Array<IRegion>>
		>;
	}

	public create(_region: ICreateRegion): Observable<IApiObject<IRegion>> {
		return this.process<IRegion>(this.api.post(`${this.url}`, _region)) as Observable<IApiObject<IRegion>>;
	}

	public update(_region: IUpdateRegion): Observable<IApiObject<IRegion>> {
		return this.process(this.api.put(`${this.url}/${_region.id}`, _region)) as Observable<IApiObject<IRegion>>;
	}

	public delete(_id: string): Observable<IApiObject<void>> {
		return this.process(this.api.delete(`${this.url}/${_id}`)) as Observable<IApiObject<void>>;
	}
}
