import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TEMPLATE_WIDTH_CHANGE } from '@shared-libs/constants';

/**
 * The service to validate data and environments
 */
@Injectable({
	providedIn: 'root',
})
export class ValidationService {
	constructor(private readonly platform: Platform) {}

	/**
	 * Validate whether a the user device is a cordova enabled device (mobile or tablet)
	 * @returns Whether the user device is cordova or capacitor enabled
	 */
	public isOnMobile(): boolean {
		return this.platform.is('capacitor') || this.platform.is('cordova');
	}

	/**
	 * Validate whether a the user device is a mobile device
	 * @returns Wheter the user device is mobile
	 */
	public isOnMobileTemplate(): boolean {
		return window.innerWidth < TEMPLATE_WIDTH_CHANGE;
	}

	/**
	 * Validate whether a the user device is a native iOS device
	 * @returns Wheter the user device is a native iOS device
	 */
	public isOnNativeiOS(): boolean {
		return this.isOnMobile() && this.platform.is('ios');
	}

	/**
	 * Validate whether a the user device is a native Android device
	 * @returns Whether the user device is a native Android device
	 */
	public isOnNativeAndroid(): boolean {
		return this.isOnMobile() && this.platform.is('android');
	}
}
