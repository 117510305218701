import { CommunicationLanguage, IInternalEmployee } from '@internal-models/employee.model';
import { orderBy, remove } from 'lodash';

import { BehaviorSubject } from 'rxjs';
import { ConsultantState } from '@internal-libs/enum';
import { EmployeeProvider } from '@internal-providers/beecloud/employee.provider';
import { IConsultantStateChange } from '@internal-models/consultant-state-change.model';
import { IRegion } from '@internal-models/region.model';
import { IUser } from '@shared-models/user.model';
import { Injectable } from '@angular/core';
import { StorageService } from '@shared-services/storage.service';
import { UserDetailsManager } from '@shared-managers/user-details.manager';
import { UserManager } from '@shared-managers/user.manager';

/* TODO: replace managers with redux strategy? */

/**
 * The manager for the employee object that is linked to the current user.
 * A Manager is responsible for the state and reusability (accross the application) of certain properties
 * @see {@link IInternalEmployee}
 */
@Injectable({
	providedIn: 'root',
})
export class EmployeeManager extends UserDetailsManager<IInternalEmployee> {
	private consultantStateSubject: BehaviorSubject<ConsultantState> = new BehaviorSubject(null);
	private employeeSubject: BehaviorSubject<IInternalEmployee> = new BehaviorSubject(null);

	public user: IUser;

	constructor(
		protected readonly storageService: StorageService,
		protected readonly userManager: UserManager,
		private readonly employeeProvider: EmployeeProvider
	) {
		super(userManager, storageService);
	}

	/**
	 * Set the employee object in session and persistent storage
	 * @param _employee The employee
	 */
	public setEmployee(_employee: IInternalEmployee): void {
		this.setUserDetails(_employee);
		this.employeeSubject.next(_employee);
		this.consultantStateSubject.next(_employee.consultantState);
	}

	/**
	 * Load the employee from persistent storage and save it in session storage
	 */
	public loadEmployee(): void {
		this.loadUserDetails();
		if (!this.isLoaded()) {
			this.renewEmployee();
			this.loadEmployee();
		}
	}

	/**
	 * Gets a new employee instance from BEECLOUD
	 * @param id The id of the employee
	 */
	public renewEmployee(id: string = this.getEmployeeId()): void {
		if (!id) {
			id = this.userManager.getEmployeeId();
		}
		this.employeeProvider.getEmployee(id, false).subscribe((result) => {
			this.setEmployee(result.data as IInternalEmployee);
		});
	}

	/**
	 * Check whether the employee is loaded or not
	 * @returns Whether the employee is loaded or not
	 */
	public isLoaded(): boolean {
		return this.userDetailsAreLoaded();
	}

	/**
	 * Remove the employee from session and persistent storage
	 */
	public removeEmployee(): void {
		this.removeUserDetails();
	}

	/**
	 * Get the id of the employee
	 * @returns The id
	 */
	public getEmployeeId(): string {
		return this.getUserDetails()?.id;
	}

	/**
	 * Get the full {@link IInternalEmployee} object from session storage
	 * @returns The employee
	 */
	public getEmployee(): IInternalEmployee {
		return this.getUserDetails();
	}

	/**
	 * Get the profile picture url of the employee
	 *
	 * When no profile picture url is found, a placeholder image url is returned
	 * @returns The profile picture url
	 */
	public getProfilePicture(): string {
		return this.getUserDetails()?.pictureUrl || './assets/icons/profile-placeholder.svg';
	}

	/**
	 * Set the profile picture url of the employee
	 * @param url The url of the stored profile picture
	 */
	public setProfilePicture(url: string): void {
		this.setUserDetailsAttribute('pictureUrl', url);
	}

	/**
	 * Get the full name (first and last combined) from the employee
	 * @returns The full employee name
	 */
	public getFullName(): string {
		const employee = this.getUserDetails();
		return employee ? `${employee.firstname} ${employee.name}` : '';
	}

	/**
	 * Get the current consultant status's name from the employee
	 * @returns The consultant status as string
	 */
	public getConsultantStatus(): string {
		return this.getUserDetails()?.ConsultantStatus?.name ?? '';
	}

	/**
	 * Get the current average appointment rating score from the employee
	 * @returns The score as number
	 */
	public getAverageRating(): number {
		return this.getUserDetails()?.averageRating ?? 0;
	}

	/**
	 * Set the current communication language from the employee
	 * @param communicationLanguage The communication language as {@link CommunicationLanguage}
	 */
	public setCommunicationLanguage(communicationLanguage: CommunicationLanguage): void {
		this.setUserDetailsAttribute('communicationLanguage', communicationLanguage);
	}

	/**
	 * Get the current consultant state from the employee
	 * @returns The consultant state as {@link ConsultantState}
	 */
	public getConsultantState(): ConsultantState {
		return this.getUserDetails()?.consultantState ?? null;
	}

	public getConsultantStateSubject(): BehaviorSubject<ConsultantState> {
		return this.consultantStateSubject; // return the subject so we can subscribe to it in the navigation
	}

	public getEmployeeSubject(): BehaviorSubject<IInternalEmployee> {
		return this.employeeSubject;
	}

	/**
	 * Set the current consultant state from the employee
	 * @param _consultantState The consultant state as {@link ConsultantState}
	 */
	public setConsultantState(_consultantState: ConsultantState): void {
		this.setUserDetailsAttribute('consultantState', _consultantState);
		this.consultantStateSubject.next(_consultantState); // emit the state change
	}

	/**
	 * Get the consultant state changes from the employee
	 * @returns The consultant state changes as array of {@link IConsultantStateChange}
	 */
	public getConsultantStateChanges(): Array<IConsultantStateChange> {
		const employee = this.getUserDetails();
		return employee && employee.ConsultantStateChanges.length > 0 ? employee.ConsultantStateChanges : [];
	}

	/**
	 * Set the consultant state changes from the employee
	 * @param _consultantStateChanges The consultant state changes as array of {@link IConsultantStateChange}
	 */
	public setConsultantStateChanges(_consultantStateChanges: Array<IConsultantStateChange>): void {
		this.setUserDetailsAttribute('ConsultantStateChanges', _consultantStateChanges);
	}

	/**
	 * Add a consultant state change to the employee in session storage
	 *
	 * When the state change is updated, the employee is stored in persistent storage
	 * @param _change The consultant state change to add
	 */
	public addConsultantStateChange(_change: IConsultantStateChange): void {
		const changes = this.getConsultantStateChanges();
		changes.push(_change);
		this.setConsultantStateChanges(orderBy(changes, ['from', 'asc']));
	}

	/**
	 * Edit a consultant state change
	 *
	 * When the state change is updated, the employee is stored in persistent storage
	 * @param _id The id of the state change that needs to be updated
	 * @param _change The consultant state change
	 * @param _change.from The consultant state change
	 * @param _change.to The consultant state change
	 */
	public updateConsultantStateChange(_id: number, _change: { from: string; to: string }): void {
		const changes = this.getConsultantStateChanges();
		const index = changes.findIndex((change) => change.id === _id);
		changes[index] = { ...changes[index], ..._change };
		this.setConsultantStateChanges(changes);
	}

	/**
	 * Remove a consultant state change from the employee
	 *
	 * When the state change is updated, the employee is stored in persistent storage
	 * @param _id The id of the consultant state change to remove
	 */
	public removeConsultantStateChange(_id: number): void {
		const changes = this.getConsultantStateChanges();
		remove(changes, (change) => change.id === _id);
		this.setConsultantStateChanges(changes);
	}

	/**
	 * Get the regions from the current employee
	 * @returns The regions as array of {@link IRegion}
	 */
	public getRegions(): Array<IRegion> {
		return this.getUserDetails()?.Regions ?? [];
	}

	/**
	 * Set the regions of the current employee
	 *
	 * When the regions are set, the employee is stored in persistent storage
	 * @param _regions The regions
	 */
	public setRegions(_regions: Array<IRegion>): void {
		this.setUserDetailsAttribute('Regions', _regions);
	}

	/**
	 * Update an existing region
	 *
	 * When the region is set, the employee is stored in persistent storage
	 * @param _region The region
	 */
	public setRegion(_region: IRegion): void {
		const regions = this.getRegions();
		const index = regions.findIndex((region) => region.id === _region.id);
		if (index !== -1) {
			regions[index] = _region;
			this.setRegions(regions);
		}
	}

	/**
	 * Add a new region to the list of regions of the employee
	 * @param _region The region to add to the list of regions
	 */
	public addRegion(_region: IRegion): void {
		const regions = this.getRegions();
		regions.push(_region);
		this.setRegions(regions);
	}

	/**
	 * Delete a region from the list of regions
	 *
	 * When the region is deleted, the employee is stored in persistent storage
	 * @param _id The id of the region
	 */
	public deleteRegion(_id: string): void {
		const regions = this.getRegions();
		remove(regions, (region) => region.id === _id);
		this.setRegions(regions);
	}

	/**
	 * Check whether a consultant is initialized or not
	 * @returns whether a consultant is initialized or not
	 */
	public isInitialized(): boolean {
		const employee = this.getUserDetails();
		return employee.ConsultantStatusId === null || employee.ConsultantStatusId >= 4;
	}
}
