import { StorageService } from '@shared-services/storage.service';
import { StorageKey, ROLE } from '@shared-libs/enums';
import { UserManager } from './user.manager';
import { Injectable } from '@angular/core';

/* TODO: replace managers with redux strategy? */

/**
 * The manager for the user details objects.
 * A Manager is responsible for the state and reusability
 * (accross the application) of certain properties
 *
 * Based on the role of the logged in user,
 * The user details can be different.
 *
 */
@Injectable({
	providedIn: 'root',
})
export class UserDetailsManager<Model = any> {
	private userDetails: Model;

	constructor(
		protected readonly userManager: UserManager,
		protected readonly storageService: StorageService
	) {}

	/**
	 * Set the user details
	 * @param _userDetails The user details
	 */
	public setUserDetails(_userDetails: Model): void {
		this.userDetails = _userDetails;
		this.storeUserDetails();
	}

	/**
	 * Set attribute of the user details
	 * @param attribute The attribute to set
	 * @param value The value to assign to the attribute
	 */
	public setUserDetailsAttribute(attribute: string, value: any): void {
		this.userDetails[attribute] = value;
		this.storeUserDetails();
	}

	/**
	 * Return the user details
	 * @returns the user's details
	 */
	public getUserDetails(): Model {
		return this.userDetails;
	}

	/**
	 * Load the user details from persistent storage and save it in session storage
	 */
	public loadUserDetails(): void {
		this.storageService.getItem<Model>(this.getStorageKeyBasedOnRole()).subscribe((_userDetails) => {
			this.userDetails = _userDetails;
		});
	}

	/**
	 * Check whether the user details are loaded or not
	 * @returns {boolean} whether or not the user details are loaded
	 */
	public userDetailsAreLoaded(): boolean {
		return !!this.userDetails;
	}

	/**
	 * Remove the user details from session and persistent storage
	 */
	public removeUserDetails(): void {
		this.userDetails = null;
		this.storageService.removeItem(this.getStorageKeyBasedOnRole()).subscribe();
	}

	/**
	 * Store the user details from session storage in persistent storage
	 */
	private storeUserDetails(): void {
		this.storageService.setItem(this.getStorageKeyBasedOnRole(), this.userDetails).subscribe(() => {});
	}

	private getStorageKeyBasedOnRole(): StorageKey {
		switch (this.userManager.getRole()) {
			case ROLE.CLIENT:
				return StorageKey.client;
			case ROLE.SOCIAL_PARTNER:
			case ROLE.PARTNER:
				return StorageKey.partner;
			default:
				return StorageKey.employee;
		}
	}
}
