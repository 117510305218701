import { BehaviorSubject, from, lastValueFrom, Observable, throwError } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { Api } from './api.provider';
import { AuthenticationService } from '@shared-services/authentication.service';
import { environment as ENV } from '@environments/environment';
import { UserManager } from '@app/modules/shared/managers/user.manager';
import { jwtDecode } from 'jwt-decode';
import { Device } from '@capacitor/device';
import { IUserSessionDeviceMeta } from '@shared-libs/interfaces';
import { AppManager } from '@shared-managers/app.manager';
import { captureException } from '@sentry/browser';
import { LoginEndpoints } from './beecloud/login.provider';
import { ValidationService } from '@shared-services/validation.service';
import { Platform } from '@ionic/angular';

/**
 * An Angular interceptor that adds the required headers to the request and handles refresh token logic
 * @augments HttpInterceptor
 */
@Injectable()
export class Interceptor implements HttpInterceptor {
	private isRefreshingToken: boolean = false;
	private readonly refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor(
		private readonly injector: Injector,
		private readonly userManager: UserManager,
		private readonly appManager: AppManager,
		private readonly platform: Platform
	) {}

	/**
	 * Intercept the request before sending it, and add the headers when sending requests to the API.
	 * When the API Authorization token is expired and the request is to the API,
	 * the refresh logic is used to re-authenticate.
	 * @param req  The HTTP request
	 * @param next The next handler for the request
	 * @returns An observable containing the HttpEvent with the request result or error
	 */
	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return from(this.handleRequest(req, next));
	}

	/**
	 * Intercept the request before sending it, and add the headers when sending requests to the API.
	 * When the API Authorization token is expired and the request is to the API,
	 * the refresh logic is used to re-authenticate.
	 * @param req The HTTP request
	 * @param next The next handler for the request
	 * @returns An observable containing the HttpEvent with the request result or error
	 */
	private async handleRequest(req: HttpRequest<any>, next: HttpHandler) {
		let request = req;

		if (req.url === ENV.BASE_URL + '/login/refresh' || req.url === ENV.BASE_URL + LoginEndpoints.login) {
			request = request.clone({
				setHeaders: {
					'device-meta': JSON.stringify(await this.getDeviceInfo()),
				},
			});
		}

		if (req.url.includes(ENV.BASE_URL) && !req.url.includes('login/refresh')) {
			const token = this.userManager.getToken();

			if (token && !request.headers.has('type')) {
				const { exp } = jwtDecode(token) as { exp: number };

				if (new Date(exp * 1000) < new Date() || req.url.includes(LoginEndpoints.isActive)) {
					return lastValueFrom(this.refreshToken(request, token, next));
				}

				request = this.setHeaders(request, token);
			} else if (request.headers.has('type')) {
				request = request.clone({ headers: request.headers.delete('type', 'external') });
			}

			return lastValueFrom(
				next.handle(request).pipe(
					catchError((err) => {
						if (err.status === 498) {
							this.refreshToken(request, token, next);
						}
						return lastValueFrom(throwError(() => err));
					})
				)
			);
		}

		return lastValueFrom(next.handle(request));
	}

	private setHeaders(_request: HttpRequest<any>, _token: string): HttpRequest<any> {
		return _request.clone({
			setHeaders: {
				Authorization: `Bearer ${_token}`,
			},
		});
	}

	/**
	 * When not in process of a request to refresh the access token, we do a request to refresh the access token
	 * and when finished we process the initial request. When we are in process of refreshing, the initial request is piped
	 * to execute after the refresh is processed. No double refreshes are done.
	 * @param request The initial request
	 * @param token The expired token
	 * @param next The httpHandler
	 * @returns An observable with the result of the request as result
	 */
	private refreshToken(request: HttpRequest<any>, token: string, next: HttpHandler): Observable<any> {
		if (!this.isRefreshingToken) {
			this.isRefreshingToken = true;
			this.refreshTokenSubject.next(null);

			return this.injector
				.get(Api)
				.post('/login/refresh', { refreshToken: this.userManager.getRefreshToken() }, { hideError: true })
				.pipe(
					switchMap((result) => {
						token = (result.data as any).token;
						this.refreshTokenSubject.next(token);
						this.userManager.saveToken(token);
						request = this.setHeaders(request, token);
						this.isRefreshingToken = false;
						return next.handle(request);
					})
				)
				.pipe(
					catchError((err) => {
						if (err.status === 401) {
							this.injector.get(AuthenticationService).logout();
						}
						return throwError(() => err);
					})
				);
		} else {
			return this.refreshTokenSubject.pipe(
				filter((token) => token != null),
				take(1),
				switchMap((_token) => next.handle(this.setHeaders(request, _token)))
			);
		}
	}

	/**
	 * Get the device info and return all OS-related properties
	 * @returns A sanitized device-meta object
	 */
	private async getDeviceInfo(): Promise<IUserSessionDeviceMeta> {
		try {
			const deviceMeta = await Device.getInfo();
			return {
				name: deviceMeta?.name,
				iOSVersion: deviceMeta?.iOSVersion,
				androidSDKVersion: deviceMeta?.androidSDKVersion,
				model: deviceMeta?.model,
				platform: deviceMeta?.platform,
				operatingSystem: deviceMeta?.operatingSystem,
				osVersion: deviceMeta?.osVersion,
				manufacturer: deviceMeta?.manufacturer,
				isVirtual: deviceMeta?.isVirtual,
				webViewVersion: deviceMeta?.webViewVersion,
				capacitorPlatforms: this.platform.platforms(),
				appVersion: this.appManager.getVersion(),
			};
		} catch (err) {
			captureException(err, { extra: { method: 'Interceptor.getDeviceInfo()' } });
			return {};
		}
	}
}
