import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RoleDirective } from './role.directive';
import { SelectIconDirective } from './select-icon.directive';
import { NotRoleDirective } from './notRole.directive';
import { NavigateDirective } from './navigate.directive';

@NgModule({
	declarations: [RoleDirective, NotRoleDirective, SelectIconDirective, NavigateDirective],
	imports: [CommonModule],
	exports: [RoleDirective, NotRoleDirective, SelectIconDirective, NavigateDirective],
})
export class DirectivesModule {}
