import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupHeaderComponent } from './popup-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { TranslationModule } from '@app/app-translation.module';

@NgModule({
	declarations: [PopupHeaderComponent],
	exports: [PopupHeaderComponent],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, IonicModule, TranslationModule],
	providers: [],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PopupHeaderComponentModule {}
